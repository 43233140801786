import { createSlice } from "@reduxjs/toolkit";

export const superAdminSlice = createSlice({
  name: "superAdmin",

  initialState: {
    tempViewingCompany: null,
  },

  reducers: {
    setViewingCompany: (state, action) => {
      state.tempViewingCompany = action.payload;
    },
  },
});

export default superAdminSlice.reducer;
