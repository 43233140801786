import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const userSlice = createSlice({
  name: "user",

  initialState: {
    user: {},
  },

  reducers: {
    logout: (state, action) => {
      localStorage.removeItem("header");
      Cookies.remove("authenticated");
      Cookies.remove("accessTokenKeycloak");
    },

    setGroupName: (state, action) => {
      state.user.customers = action.payload.customers.toLowerCase().trim();
    },

    loginUser: (state, action) => {
      state.user = action.payload;
    },
    updateCustomers: (state, action) => {
      const newArray = [...state.user.customers];
      const index = newArray.findIndex((i) => i.id === action.payload.id);
      newArray[index].originalCustomerName = action.payload.name;
      state.user.customers = newArray;
    },
    setUserFavorites: (state, action) => {
      state.user.favorites = action.payload.favorites;
    },
    addObjectToFavorite: (state, action) => {
      state.user.favorites = [...state.user.favorites, action.payload];
    },
    removeObjectFromFavorite: (state, action) => {
      state.user.favorites = [
        ...state.user.favorites.filter((i) => i.id !== action.payload.id),
      ];
    },
    reloadCustomersAction: (state, action) => {
      state.user.reloadCustomers = action.payload.reload;
      state.user.customers = [
        ...state.user.customers,
        ...action.payload.customers,
      ];
    },
  },
});

export const {
  logout,
  loginUser,
  updateCustomers,
  setGroupName,
  setUserFavorites,
  addObjectToFavorite,
  removeObjectFromFavorite,
  reloadCustomersAction,
} = userSlice.actions;

export default userSlice.reducer;
