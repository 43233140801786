import { createSlice } from "@reduxjs/toolkit";

export const objectFilterSlice = createSlice({
  name: "object_filters",
  initialState: {
    objectFilter: "ENTITY_UNSPECIFIED",
    selectedObjectFilter: "ENTITY_UNSPECIFIED",
  },
  reducers: {
    setSelectedObjectFilter: (state, action) => {
      state.selectedObjectFilter = action.payload;
    },
    setObjectFilter: (state, action) => {
      state.objectFilter = action.payload;
    },
  },
});

export const { setSelectedObjectFilter, setObjectFilter } =
  objectFilterSlice.actions;
export default objectFilterSlice.reducer;
