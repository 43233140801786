import { createSlice } from "@reduxjs/toolkit";

export const usersFilterSlice = createSlice({
  name: "filters",
  initialState: {
    validity: [],
    selectedValidity: [
      {
        id: undefined,
        description: "View all",
        customerName: "view_all",
      },
    ],
    roles: [],
    selectedRoles: [
      {
        id: undefined,
        customerName: "view_all",
        description: "View all",
      },
    ],
  },

  reducers: {
    setSelectedValidity: (state, action) => {
      const newValidityItem = action.payload.validity;
      const maxItems = action.payload.maxItems;

      if (newValidityItem.description === "View all") {
        // If "View all" is clicked, remove all other items
        state.selectedValidity = [newValidityItem];
      } else {
        // If another item is clicked, remove "View all" if it exists
        state.selectedValidity = state.selectedValidity.filter(
          (item) => item.description !== "View all"
        );

        // Check if the item already exists in selectedValidity
        const itemExists = state.selectedValidity.some(
          (item) => item.description === newValidityItem.description
        );

        // If the item doesn't exist, add it to the array
        if (!itemExists) {
          state.selectedValidity = [...state.selectedValidity, newValidityItem];
        }
      }
      if (state.selectedValidity.length === maxItems) {
        state.selectedValidity = [
          {
            id: undefined,
            description: "View all",
            customerName: "view_all",
          },
        ];
      }
    },
    setValidity: (state, action) => {
      state.validity = action.payload;
    },
    setSelectedRoles: (state, action) => {
      const newValidityItem = action.payload.roles;
      const maxItems = action.payload.maxItems;

      if (newValidityItem.description === "View all") {
        // If "View all" is clicked, remove all other items
        state.selectedRoles = [newValidityItem];
      } else {
        // If another item is clicked, remove "View all" if it exists
        state.selectedRoles = state.selectedRoles.filter(
          (item) => item.description !== "View all"
        );

        // Check if the item already exists in selectedValidity
        const itemExists = state.selectedRoles.some(
          (item) => item.description === newValidityItem.description
        );

        // If the item doesn't exist, add it to the array
        if (!itemExists) {
          state.selectedRoles = [...state.selectedRoles, newValidityItem];
        }
      }
      if (state.selectedRoles.length === maxItems) {
        state.selectedRoles = [
          {
            id: undefined,
            description: "View all",
            customerName: "view_all",
          },
        ];
      }
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
  },
});

export const { setSelectedValidity, setValidity, setSelectedRoles, setRoles } =
  usersFilterSlice.actions;

export default usersFilterSlice.reducer;
