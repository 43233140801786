import { useSelector } from "react-redux";
import loadingBar from "../../svg/loadingBar.svg";
import loadingText from "../../svg/loadingText.svg";
import LogoWeb from "../../../assets/logos/SrenityLogoTextWeb.svg";

const LoadingFullScreen = () => {
  const { isMobileView } = useSelector((state) => state.settings);

  return (
    <div className="min-height-100vh d-flex flex-column align-items-center pt-3 background-theme-1">
      <img src={LogoWeb} width={96} height={27} alt="" />
      <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1">
        <img
          src={loadingBar}
          width={isMobileView ? 119.98 : 150}
          height={isMobileView ? 33.81 : 30}
          alt=""
        />
        <img
          src={loadingText}
          width={isMobileView ? 54 : 60}
          height={isMobileView ? 20 : 30.38}
          alt=""
        />
      </div>
    </div>
  );
};

export default LoadingFullScreen;
