import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: "searchData",

  initialState: {
    searchKeyword: "",
    reloadComponent: false,
  },

  reducers: {
    setSearchData: (state, action) => {
      state.searchKeyword = action.payload.searchKeyword;
      state.reloadComponent = action.payload.reloadComponent;
    },
  },
});

export const { setSearchData } = searchSlice.actions;

export default searchSlice.reducer;
