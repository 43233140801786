import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import settingsReducer from "../features/settings/settingsSlice";
import userReducer from "../features/user/userSlice";
import breadcrumbSlice from "../features/breadcumb/breadcrumbSlice";
import searchReducer from "../features/searchData/searchSlice";
import superAdminReducer from "../features/superAdmin/superAdminSlice";
import filterReducer from "../features/filters/usersFilterSlice";
import deviceFilterReducer from "../features/filters/deviceFilterSlice";
import objectFilterReducer from "../features/filters/objectsFilterSlice";
function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

const persistedState = loadFromLocalStorage();

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    settings: settingsReducer,
    user: userReducer,
    breadcrumb: breadcrumbSlice,
    searchData: searchReducer,
    superAdmin: superAdminReducer,
    filters: filterReducer,
    deviceFilters: deviceFilterReducer,
    objectFilters: objectFilterReducer,
  },
  preloadedState: persistedState,
});

store.subscribe(() =>
  saveToLocalStorage({
    breadcrumb: store.getState().breadcrumb,
  })
);
