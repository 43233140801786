import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import LoadingFullScreen from "./components/common/Loading/LoadingFullScreen";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { registerLicense } from "@syncfusion/ej2-base";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { env } from "./setupEnv";

registerLicense(env.REACT_APP_REGISTER_KEY_SYNCFUSION);
const App = lazy(() => import("./App"));
const queryClient = new QueryClient();
ReactGA.initialize("G-394M4BCDND");

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Suspense fallback={<LoadingFullScreen />}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </BrowserRouter>
      </Suspense>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
