import { createSlice } from "@reduxjs/toolkit";

export const breadcrumbSlice = createSlice({
  name: "breadcrumb",

  initialState: {
    breadcrumbArray: [],
  },

  reducers: {
    updateBreadcrumb: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.breadcrumbArray = [
          {
            label: "Start",
            path: "/d/start",
          },
          ...action.payload,
        ];
      } else {
        state.breadcrumbArray = [
          {
            label: "Start",
            path: "/d/start",
          },
          action.payload,
        ];
      }
    },
  },
});

export const { updateBreadcrumb } = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
