import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",

  initialState: {
    locale: "en",
    isMobileView: false,
  },

  reducers: {
    setMobileView: (state) => {
      state.isMobileView = true;
    },
    setWebView: (state) => {
      state.isMobileView = false;
    },
  },
});

export const { setMobileView, setWebView } = settingsSlice.actions;

export default settingsSlice.reducer;
