import { createSlice } from "@reduxjs/toolkit";

export const devicesFilterSlice = createSlice({
  name: "device_filters",
  initialState: {
    deviceType: [],
    selectedDeviceType: [
      {
        name: "View all",
      },
    ],
    providerName: [],
    selectedProviderName: [
      {
        name: "View all",
      },
    ],
    realEstate: [],
    selectedRealEstate: [
      {
        id: undefined,
        customerName: "view_all",
        name: "View all",
      },
    ],
    deviceStatus: "STATUS_UNSPECIFIED",
    selectedDeviceStatus: "STATUS_UNSPECIFIED",
  },
  reducers: {
    setSelectedDeviceType: (state, action) => {
      const newValidityItem = action.payload.deviceType;
      const maxItems = action.payload.maxItems;

      if (newValidityItem.name === "View all") {
        // If "View all" is clicked, remove all other items
        state.selectedDeviceType = [newValidityItem];
      } else {
        // If another item is clicked, remove "View all" if it exists
        state.selectedDeviceType = state.selectedDeviceType.filter(
          (item) => item.name !== "View all"
        );

        // Check if the item already exists in selectedValidity
        const itemExists = state.selectedDeviceType.some(
          (item) => item.name === newValidityItem.name
        );

        // If the item doesn't exist, add it to the array
        if (!itemExists) {
          state.selectedDeviceType = [
            ...state.selectedDeviceType,
            newValidityItem,
          ];
        }
      }
      if (state.selectedDeviceType.length === maxItems) {
        state.selectedDeviceType = [
          {
            name: "view_all",
          },
        ];
      }
    },
    setDeviceType: (state, action) => {
      state.deviceType = action.payload;
    },
    setSelectedProviderName: (state, action) => {
      const newValidityItem = action.payload.providerName;
      const maxItems = action.payload.maxItems;

      if (newValidityItem.name === "View all") {
        // If "View all" is clicked, remove all other items
        state.selectedProviderName = [newValidityItem];
      } else {
        // If another item is clicked, remove "View all" if it exists
        state.selectedProviderName = state.selectedProviderName.filter(
          (item) => item.name !== "View all"
        );

        // Check if the item already exists in selectedValidity
        const itemExists = state.selectedProviderName.some(
          (item) => item.name === newValidityItem.name
        );

        // If the item doesn't exist, add it to the array
        if (!itemExists) {
          state.selectedProviderName = [
            ...state.selectedProviderName,
            newValidityItem,
          ];
        }
      }
      if (state.selectedProviderName.length === maxItems) {
        state.selectedProviderName = [
          {
            name: "View all",
          },
        ];
      }
    },
    setProviderName: (state, action) => {
      state.providerName = action.payload;
    },
    setSelectedRealEstate: (state, action) => {
      const newRealEstateItem = action.payload.realEstate;
      const maxItems = action.payload.maxItems;

      if (newRealEstateItem.name === "View all") {
        // If "View all" is clicked, remove all other items
        state.selectedRealEstate = [newRealEstateItem];
      } else {
        // If another item is clicked, remove "View all" if it exists
        state.selectedRealEstate = state.selectedRealEstate.filter(
          (item) => item.name !== "View all"
        );

        // Check if the item already exists in selectedRealEstate by comparing ids
        const itemExists = state.selectedRealEstate.some(
          (item) => item.id === newRealEstateItem.id
        );

        // If the item doesn't exist, add it to the array
        if (!itemExists) {
          state.selectedRealEstate = [
            ...state.selectedRealEstate,
            newRealEstateItem,
          ];
        }
      }
      if (state.selectedRealEstate.length === maxItems) {
        state.selectedRealEstate = [
          {
            name: "View all",
            id: undefined, // You can set the id to undefined for consistency
          },
        ];
      }
    },
    setRealEstate: (state, action) => {
      state.realEstate = action.payload;
    },
    setSelectedDeviceStatus: (state, action) => {
      state.selectedDeviceStatus = action.payload;
    },
    setDeviceStatus: (state, action) => {
      state.deviceStatus = action.payload;
    },
  },
});

export const {
  setSelectedDeviceType,
  setDeviceType,
  setSelectedProviderName,
  setProviderName,
  setSelectedRealEstate,
  setRealEstate,
  setSelectedDeviceStatus,
  setDeviceStatus,
} = devicesFilterSlice.actions;
export default devicesFilterSlice.reducer;
