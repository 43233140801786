import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";

const render = () => {
  import("./assets/sass/index.scss").then(() => {
    require("./AppRenderer");
  });
};

render();
